import React from "react"

export default function RegistrationData() {
  // const [registrationData, setRegistrationData] = useState([])
  // async function getData() {
  //   const getData = await fetch("http://localhost:1337/api/event-registrations")
  //   console.log("get data", getData)

  //   // const getData = await fetch("https://api.csr.skillsscale.in/api/coupons")
  //   let json = await getData.json()
  //   json = json["data"]
  //   setRegistrationData(json)
  //   console.log("json", json)
  // }

  //   getData()

  return (
    <>
      <div>testing</div>
      {/* {registrationData.map(data => {
        return <div>{data.id}</div>
      })} */}
    </>
  )
}
